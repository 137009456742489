<template>
    <div class="card text-white bg-primary border shadow custom-card">
        <div class="card-body">

            <h4 class="card-title"><i class="bi mx-3" :class="icon"></i>{{ title }}</h4>
            <hr>
            <br>
            <p class="card-text fs-4">{{ msg }}</p>
            <br>
            <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        title: String,
        msg: String,
        icon: String,
    }
}
</script>
<style>
.custom-card {
    min-height: 270px;
}
</style>