<template>
    <header>
        <div class="row py-4 ">
            <div class="col-1"></div>
            <div class="col-10">
                <img src="@/assets/img/products/title.png" alt="" style="width: 100%;">
                <div class="text-center py-2 text-custom-red">
                    <h2>
                        <p>{{ $t('product.title') }}</p>
                    </h2>
                    <p class="fs-2">
                        {{ $t('product.subtitle') }}
                      </p>
                    <p class="py-2 text-secondary">
                        {{ $t('product.titleText') }}

                    </p>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
    </header>
    <main>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <div class="text-custom-red">
                    <h2>
                        {{ $t('product.mainTitle') }}
                    </h2>
                    <p class="fs-2">
                        {{ $t('product.mainSubTitle') }}

                    </p>
                </div>
                <div class="row text-custom-orange">
                    <div class="col-lg-6 col-sm-12 py-3">
                        <div class="text-center">
                            <hr>
                            <p class="fs-3">

                                {{ $t('product.productName1') }}

                            </p>
                            <hr>
                        </div>
                        <img src="@/assets/img/products/indoor.png" alt="" width="100%">
                    </div>
                    <div class="col-lg-6 col-sm-12 py-3">
                        <div class="text-center">
                            <hr>
                            <p class="fs-3">
                                {{ $t('product.productName2') }}
                            </p>
                            <hr>
                        </div>
                        <img src="@/assets/img/products/out-door.png" alt="" width="100%">
                    </div>
                    <div class="col-lg-6 col-sm-12 py-3">
                        <div class="text-center">
                            <hr>
                            <p class="fs-3">
                                {{ $t('product.productName3') }}
                            </p>
                            <hr>
                        </div>

                        <img src="@/assets/img/products/industrial.jpg" alt="" width="100%">
                    </div>
                    <div class="col-lg-6 col-sm-12 py-3">
                        <div class="text-center">
                            <hr>
                            <p class="fs-3">
                                {{ $t('product.productName4') }}

                            </p>
                            <hr>
                        </div>
                        <img src="@/assets/img/products/commercial.png" alt="" width="100%">
                    </div>
                </div>
            </div>
            <div class="col-1"></div>
        </div>
    </main>
</template>
<script>
import ProductDetail from '@/components/ProductDetail.vue';
import Products from '@/components/Products.vue';
export default {
    data() {
        return {
            title: 'PRODUCT PORTAL FOR PROFESSIONAL CUSTOMERS',
            subtitle: 'FIND THE ENTIRE PRODUCT PORTFOLIO AT A GLANCE HERE',
            titleText: `We offer one of the world's most comprehensive product portfolios in general lighting. Modern lighting solutions for your projects. Energy-efficient, durable and flexible in use. For almost every purpose. You have the choice between very different LED luminaires, electronic lighting components, smart lighting solutions and LED lamps.`,
            mainTitle: ' PRODUCT CATALOG',
            mainSubTitle: 'ALL TECHNICAL DATA AND DATA SHEETS',
            productName1: 'Indoor Lighting',
            productName2: 'Outdoor Lighting',
            productName3: 'Commercial Lighting',
            productName4: 'Industrial Lighting',
        };
    },
    components: {
    },

}
</script>