<template>
    <div class="bg-custom-red">
        <div class="row py-5">
            <div class="col col-lg-1"></div>

            <!-- l-text -->
            <div class="col col-lg-5 px-4 py-4 text-white">
                <div class="">
                    <h1 class="">{{ title }}</h1>
                    <hr class="" style="height: 20px;">
                    <p class="fs-3">{{ titleDetails }}</p>
                </div>
            </div>
            <!-- r-phote -->
            <div class="col col-lg-5 px-5 py-5">
                <img src="../assets/img/light1.jpg" alt="" style="width: 100%;">
            </div>

            <div class="col col-lg-1 "></div>

        </div>
        <div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: 'Illuminate Your Space with Stunning Lighting Solutions',
            titleDetails: 'Enhance the Ambiance, Functionality, and Energy Efficiency of Any Room',
            mainPart1Title: 'Lighting Solutions that Illuminate Your Space',
            mainPart1Content1: 'Enhance Ambiance, Functionality, and Energy Efficiency',
            mainPart1Content2: `LightingCo is the leading provider of high-quality electric lighting products. Whether you're a homeowner looking to create a cozy atmosphere or a business owner in need of stylish and efficient lighting solutions, we have you covered. Our wide range of lighting options caters to residential, commercial, and industrial applications. From elegant chandeliers to energy-saving LED bulbs, our products are designed to elevate any space. With competitive prices and exceptional customer service, LightingCo is your one-stop shop for all your lighting needs.`,
        }
    }
}
</script>