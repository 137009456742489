<template >
  <header class="row">
    <NavbarContent />
  </header>
  <main class="bg-light">
    <router-view />
  </main>
  <footer class="bg-primary">
    <FooterContent />
  </footer>
</template>
<script>
import FooterContent from '@/components/Footer.vue'
import NavbarContent from '@/components/Navbar.vue'
export default {
  components: {
    FooterContent,
    NavbarContent
  }
}
</script>
<style>
/* main {
  min-height: 992px;
} */

.bg-custom-red {
  background-color: #dc3545;
}

.bg-custom-orange {
  background-color: #fd7e14;
}

.text-custom-orange {
  color: #fd7e14;
}

.text-custom-red {
  color: #dc3545;
}

</style>
