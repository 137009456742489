export default {
    'navbar': {
        navBarBrand: 'SC-Light',
        navBarItem1: '主页介绍',
        navBarItem2: '产品信息',
        navBarItem3: '公司信息',
        navBarItem4: '联系我们',
        navBarItem5: '翻译',
        navBarItem6: '应用领域',
        navBarItem7: '产品情报',
    },
    'homepage': {
        btnMsg: '查看详情',
    },
    'homepageHeader': {
        title: '用令人惊叹的照明解决方案照亮您的空间',
        titleDetails: '增强任何房间的氛围、功能和能源效率',
        mainPart1Title: '照亮您空间的照明解决方案',
        mainPart1Content1: '增强氛围、功能和能源效率',
        mainPart1Content2: `SC-Light 是高品质电气照明产品的领先供应商。无论您是想要营造舒适氛围的房主，还是需要时尚高效的照明解决方案的企业主，我们都能满足您的需求。我们广泛的照明选项可满足住宅、商业和工业应用的需求。从优雅的吊灯到节能的 LED 灯泡，我们的产品旨在提升任何空间的品质。 SC-Light 拥有具有竞争力的价格和卓越的客户服务，是满足您所有照明需求的一站式商店。`,
        btnText1: `我们公司的信息`,
        btnText2: '联系我们'
    },
    'homepageContent': {
        mainPart1Title: '适合每个空间的时尚高效的照明解决方案',
        mainPart1Content1: '提升您的氛围、功能和能源效率',
        mainPart1Content2: `SC-Light 提供各种高品质电气照明产品，旨在增强任何住宅、商业或工业空间的品质。从优雅的吊灯到节能的 LED 灯泡，我们的解决方案专为满足您的风格和效率需求而量身定制。`,
        mainPart2Title: '用令人惊叹的照明解决方案照亮您的空间',
        mainPart2Content1: '增强任何房间的氛围、功能和能源效率',
        mainPart2Content2: `SC-Light 是高品质电气照明产品的领先供应商，这些产品旨在增强任何空间的氛围、功能和能源效率。凭借我们广泛的选择、具有竞争力的价格和卓越的客户服务，我们是满足您所有照明需求的一站式商店。`,
        mainPart3Title: '用高品质的照明解决方案照亮您的空间',
        mainPart3Content1: '增强氛围、功能和能源效率',
        mainPart3Content2: `SC-Light 提供各种高品质电气照明产品，旨在增强任何空间的氛围、功能和能源效率。无论您是想要营造舒适氛围的房主，还是需要时尚高效的照明解决方案的企业主，我们都能满足您的需求。`,
        cardArray: [
            {
                cardIcon: 'bi bi-search',
                cardTitle: 'Wide Range of Lighting Solutions',
                cardContent: 'Find the Perfect Fit for Your Space',
            },
            {
                cardIcon: 'bi-lightbulb-fill',
                cardTitle: 'Enhance Ambiance and Functionality',
                cardContent: 'Create the Perfect Atmosphere for Any Occasion',
            },
            {
                cardIcon: 'bi bi-graph-up-arrow',
                cardTitle: 'Energy-Efficient Options Available',
                cardContent: 'Subheadline: Save on Energy Bills while Reducing Environmental Impact',
            },
            {
                cardIcon: 'bi bi-lightning-charge-fill',
                cardTitle: 'Adjustable Brightness Options',
                cardContent: 'Explore Lighting Products with Customizable Brightness Levels.',
            },
            {
                cardIcon: 'bi bi-person-plus',
                cardTitle: 'Designed for All Users',
                cardContent: 'Lighting Solutions Tailored for Homeowners and Professionals.',
            },
            {
                cardIcon: 'bi bi-bullseye',
                cardTitle: 'Excellence in Every Aspect',
                cardContent: 'Our Lighting Aims for Efficiency, Aesthetics, and Versatility.',
            },
        ],
    },
    'homepageFoot':{
        title: '探索适合您空间的完美照明解决方案',
        text: '增强氛围、功能和能源效率',
        leftBtn: '现在购买',
        rightBtn: '联系我们',
    },
    'product': {
        title: '专业客户的产品门户',
        subtitle: '在这里一目了然地找到整个产品组合',
        titleText: `我们提供世界上最全面的普通照明产品组合之一。适合您项目的现代照明解决方案。节能、耐用、使用灵活。几乎适用于所有目的。您可以选择截然不同的 LED 灯具、电子照明组件、智能照明解决方案和 LED 灯。`,
        mainTitle: ' 产品目录',
        mainSubTitle: 'ALL TECHNICAL DATA AND DATA SHEETS',
        productName1: '室内照明',
        productName2: '户外照明',
        productName3: '商业照明',
        productName4: '工业照明',
    },
    'aboutTCL': {
        mainText1: `TCL创立于1981年，前身为中国首批合资企业之一——TTK家庭电器有限公司，最初从事磁带的生产制造，后来拓展到电话、电视、手机、空调、冰箱、洗衣机、照明、小家电等产业，再到布局智能终端、半导体显示、新能源光伏、产业金融及投资平台等领域，业务范围不断拓展。TCL至今已在全球布局46个研发中心、32个制造基地，业务遍及全球160多个国家和地区，成为具有全球竞争力的智能科技产业集团。`,
        mainText2: `TCL华瑞照明创立于2000年，由TCL集团旗下的TCL照明和华瑞光源合并而成， 是集研发、生产、销售LED及LED应用解决方案于一体的高新技术企业。公司致力于发展先进的Micro LED显示、Mini LED背光和显示等部品业务，以及LED绿色照明和智能照明业务，获得了“中国绿色照明教育示范基地”荣誉称号`,
        mainText3: '目前，TCL华瑞照明拥有多条独立的Mini LED开发生产线和完善的制程管控体系，协同产业链综合优势资源，提供最佳产品应用解决方案。凭借自身先进的技术方案，TCL华瑞照明配合TV领域客户完成多个定制化Mini LED背光模组项目，获得一致认可。',
        mainText4: `在LED照明应用领域，TCL华瑞照明深耕行业二十多年，以创新科技研发为依托，将健康和智能照明作为发展重点，以市场为导向，专业服务于市政交通、办公、家居、地产、商业、教育、医疗、工业、户外等各个领域，在全国大中型城市设有办事机构，渠道网点覆盖各省市县镇，为客户提供高品质的照明产品、整体解决方案与服务；同时，积极进军海外市场，在欧美、中东和东南亚等市场建立有稳定的客户合作与业务服务体系。`,
        mainText5: `TCL华瑞照明在行业内率先建立起CNAS认可的国家级照明实验室，并建立了EMC、分布光度等行业内标杆类的专业试验室，通过了美国UL WTDP目击测试实验室资质认证，打造国际化的质量管理体系，与多所知名高校进行产、学、研合作，形成强大研发力量，始终保持科技创新的竞争力。`,
        mainText6: `基于自身LED光源器件封装领域的品质和技术积累，以及LED领域“封装+背光模组+照明应用”的全产业链平台，TCL华瑞照明努力成为受人尊敬的先进显示和健康智能照明领先企业。`,
    },
}