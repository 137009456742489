export default {
    'navbar': {
        navBarBrand: 'SC-Light',
        navBarItem1: 'Homepage',
        navBarItem2: 'Product',
        navBarItem3: 'Company Info',
        navBarItem4: 'Contact',
        navBarItem5: 'Translation',
        navBarItem6: 'Applications',
        navBarItem7: 'Products',
    },
    'homepage': {
        btnMsg: 'Click to details',
        mainPart1Title: 'Lighting Solutions that Illuminate Your Space',
        mainPart1Content1: 'Elevate Your Ambiance, Functionality, and Energy Efficiency',
        mainPart1Content2: `SC-Light offers a wide range of high-quality electric lighting products designed to enhance any residential, commercial, or industrial space. From elegant chandeliers to energy-saving LED bulbs, our solutions are tailored to meet your needs with style and efficiency.`,


    },
    'homepageHeader': {
        title: 'Illuminate Your Space with Stunning Lighting Solutions',
        titleDetails: 'Enhance the Ambiance, Functionality, and Energy Efficiency of Any Room',
        mainPart1Title: 'Lighting Solutions that Illuminate Your Space',
        mainPart1Content1: 'Enhance Ambiance, Functionality, and Energy Efficiency',
        mainPart1Content2: `SC-Light is the leading provider of high-quality electric lighting products. Whether you're a homeowner looking to create a cozy atmosphere or a business owner in need of stylish and efficient lighting solutions, we have you covered. Our wide range of lighting options caters to residential, commercial, and industrial applications. From elegant chandeliers to energy-saving LED bulbs, our products are designed to elevate any space. With competitive prices and exceptional customer service, SC-Light is your one-stop shop for all your lighting needs.`,
        btnText1: `Take a look at our company’s information`,
        btnText2: 'Contack Me'
    },
    'homepageContent': {
        mainPart1Title: 'Stylish and Efficient Lighting Solutions for Every Space',
        mainPart1Content1: 'Elevate Your Ambiance, Functionality, and Energy Efficiency',
        mainPart1Content2: `SC-Light offers a wide range of high-quality electric lighting products designed to enhance any residential, commercial, or industrial space. From elegant chandeliers to energy-saving LED bulbs, our solutions are tailored to meet your needs with style and efficiency.`,
        mainPart2Title: 'Illuminate Your Space with Stunning Lighting Solutions',
        mainPart2Content1: 'Enhance the Ambiance, Functionality, and Energy Efficiency of Any Room',
        mainPart2Content2: `SC-Light is a leading provider of high-quality electric lighting products that are designed to enhance the ambiance, functionality, and energy efficiency of any space. With our extensive selection, competitive prices, and exceptional customer service, we are your one-stop shop for all your lighting needs.`,
        mainPart3Title: 'Illuminate Your Space with High-Quality Lighting Solutions',
        mainPart3Content1: 'Enhance Ambiance, Functionality, and Energy Efficiency',
        mainPart3Content2: `SC-Light offers a wide range of high-quality electric lighting products designed to enhance the ambiance, functionality, and energy efficiency of any space. Whether you're a homeowner looking to create a cozy atmosphere or a business owner in need of stylish and efficient lighting solutions, we have you covered.`,
        title: 'Benefits of Choosing SC-Light',
        cardArray: [
            {
                cardIcon: 'bi bi-search',
                cardTitle: 'Wide Range of Lighting Solutions',
                cardContent: 'Find the Perfect Fit for Your Space',
            },
            {
                cardIcon: 'bi-lightbulb-fill',
                cardTitle: 'Enhance Ambiance and Functionality',
                cardContent: 'Create the Perfect Atmosphere for Any Occasion',
            },
            {
                cardIcon: 'bi bi-graph-up-arrow',
                cardTitle: 'Energy-Efficient Options Available',
                cardContent: 'Subheadline: Save on Energy Bills while Reducing Environmental Impact',
            },
            {
                cardIcon: 'bi bi-lightning-charge-fill',
                cardTitle: 'Adjustable Brightness Options',
                cardContent: 'Explore Lighting Products with Customizable Brightness Levels.',
            },
            {
                cardIcon: 'bi bi-person-plus',
                cardTitle: 'Designed for All Users',
                cardContent: 'Lighting Solutions Tailored for Homeowners and Professionals.',
            },
            {
                cardIcon: 'bi bi-bullseye',
                cardTitle: 'Excellence in Every Aspect',
                cardContent: 'Our Lighting Aims for Efficiency, Aesthetics, and Versatility.',
            },
        ],
    },
    'homepageFoot':{
        title: 'Discover the Perfect Lighting Solutions for Your Space',
        text: 'Enhance Ambiance, Functionality, and Energy Efficiency',
        leftBtn: 'SHOP NOW',
        rightBtn: 'CONTACT',
    },
    'product': {
        title: 'PRODUCT PORTAL FOR PROFESSIONAL CUSTOMERS',
        subtitle: 'FIND THE ENTIRE PRODUCT PORTFOLIO AT A GLANCE HERE',
        titleText: `We offer one of the world's most comprehensive product portfolios in general lighting. Modern lighting solutions for your projects. Energy-efficient, durable and flexible in use. For almost every purpose. You have the choice between very different LED luminaires, electronic lighting components, smart lighting solutions and LED lamps.`,
        mainTitle: ' PRODUCT CATALOG',
        mainSubTitle: 'ALL TECHNICAL DATA AND DATA SHEETS',
        productName1: 'Indoor Lighting',
        productName2: 'Outdoor Lighting',
        productName3: 'Commercial Lighting',
        productName4: 'Industrial Lighting',
    },
    'aboutTCL': {
        mainText1: `TCL was founded in 1981. It was formerly known as TTK Home Appliances Co., Ltd., one of the first joint ventures in China. It was initially engaged in the production and manufacturing of tapes, and later expanded to telephones, TVs, mobile phones, air conditioners, refrigerators, washing machines, lighting, small household appliances, etc. industry, and then to the layout of intelligent terminals, semiconductor displays, new energy photovoltaics, industrial finance and investment platforms, etc., the business scope continues to expand. TCL has so far deployed 46 R&D centers and 32 manufacturing bases around the world, with operations in more than 160 countries and regions around the world, becoming a globally competitive smart technology industry group.`,
        mainText2: `TCL Huarui Lighting was founded in 2000 by the merger of TCL Lighting and Huarui Light Source, a subsidiary of TCL Group. It is a high-tech enterprise integrating R&D, production and sales of LED and LED application solutions. The company is committed to developing advanced Micro LED display, Mini LED backlight and display and other component businesses, as well as LED green lighting and smart lighting business, and has won the honorary title of "China Green Lighting Education Demonstration Base"`,
        mainText3: `Currently, TCL Huarui Lighting has multiple independent Mini LED development and production lines and a complete process control system, cooperating with the comprehensive advantageous resources of the industry chain to provide the best product application solutions. Relying on its advanced technical solutions, TCL Huarui Lighting has cooperated with customers in the TV field to complete multiple customized Mini LED backlight module projects and received unanimous recognition.
        `,
        mainText4: `In the field of LED lighting applications, TCL Huarui Lighting has been deeply involved in the industry for more than 20 years. Relying on innovative technology research and development, it takes health and intelligent lighting as the focus of development. It is market-oriented and professionally serves municipal transportation, offices, homes, real estate, In various fields such as commerce, education, medical care, industry, and outdoor, we have offices in large and medium-sized cities across the country, with channel outlets covering all provinces, cities, counties, and towns, providing customers with high-quality lighting products, overall solutions, and services; at the same time, we are actively expanding into In overseas markets, we have established stable customer cooperation and business service systems in markets such as Europe, the United States, the Middle East and Southeast Asia.`,
        mainText5: `TCL Huarui Lighting took the lead in the industry to establish a national-level lighting laboratory recognized by CNAS, and established benchmarking professional laboratories for EMC, goniophotometry and other industries. It passed the U.S. UL WTDP witness test laboratory qualification certification, creating an international It has a professional quality management system and cooperates with many well-known universities in industry, academia and research to form a strong R&D force and always maintain the competitiveness of scientific and technological innovation.`,
        mainText6: `Based on its quality and technology accumulation in the field of LED light source device packaging, as well as the entire industry chain platform of "packaging + backlight module + lighting application" in the LED field, TCL Huarui Lighting strives to become a respected leader in advanced display and healthy intelligent lighting.`,
    },
}