<template>
    <div class="bg-warning">
        <div class="row py-5">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 px-4 py-4">
                <div class="text-center py-4">
                    <h1 class="fw-bold text-custom-orange">{{ title }}</h1>
                </div>
                <div class="row">
                    <Card v-for="(item, index) in cardArray" :key="index" :cardBox="item" />
                </div>
            </div>
            <div class="col-lg-1">
            </div>
        </div>

    </div>
</template>

<script>
import Card from './Card.vue';
export default {
    data() {
        return {
            title: 'Benefits of Choosing SC-Light',
            cardArray: [
                {
                    cardIcon: 'bi bi-search',
                    cardTitle: 'Wide Range of Lighting Solutions',
                    cardContent: 'Find the Perfect Fit for Your Space',
                },
                {
                    cardIcon: 'bi-lightbulb-fill',
                    cardTitle: 'Enhance Ambiance and Functionality',
                    cardContent: 'Create the Perfect Atmosphere for Any Occasion',
                },
                {
                    cardIcon: 'bi bi-graph-up-arrow',
                    cardTitle: 'Energy-Efficient Options Available',
                    cardContent: 'Subheadline: Save on Energy Bills while Reducing Environmental Impact',
                },
                {
                    cardIcon: 'bi bi-lightning-charge-fill',
                    cardTitle: 'Adjustable Brightness Options',
                    cardContent: 'Explore Lighting Products with Customizable Brightness Levels.',
                },
                {
                    cardIcon: 'bi bi-person-plus',
                    cardTitle: 'Designed for All Users',
                    cardContent: 'Lighting Solutions Tailored for Homeowners and Professionals.',
                },
                {
                    cardIcon: 'bi bi-bullseye',
                    cardTitle: 'Excellence in Every Aspect',
                    cardContent: 'Our Lighting Aims for Efficiency, Aesthetics, and Versatility.',
                },
            ],

        }
    },
    components: {
        Card,
    }
}
</script>
<style>
.custom-hr {
    margin-top: 7px;
    margin: 0;
    border: 0;
    color: white;
    background-color: white;
    height: 20px
}
</style>