<template>
    <div class="bg-custom-white">
        <div class="row py-5">
            <div class="col-lg-1"></div>
            <div class="col-lg-10 px-4 py-4">
                <h1 class="fw-bold">{{ $t('homepageHeader.mainPart1Title') }}</h1>
                <hr class="custom-hr ">
                <p class="fs-5">{{ $t('homepageHeader.mainPart1Content1') }}</p>
                <p class="fs-5">{{ $t('homepageHeader.mainPart1Content2') }}</p>
            </div>
            <div class="col-lg-1">
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>
<style>
.custom-hr {
    margin-top: 7px;
    margin: 0;
    border: 0;
    color: white;
    background-color: white;
    height: 20px
}
</style>