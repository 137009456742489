<template>
  <div class="home">
    <HomeContentHeader />
    <HomeContentTotalText />
    <HomeContentLeftPhote :bg="bgWarning" :title="titleOrg" :text="textWhite" :textBox="textBox1"
      :photeSrc="require('../assets/img/light2.jpg')" />
    <HomeContentRightPhote :bg="bgwhite" :title="titleOrg" :text="textDark" :textBox="textBox2"
      :photeSrc="require('../assets/img/light3.jpg')" />
    <HomeContentLeftPhote :bg="bgwhite" :title="titleOrg" :text="textDark" :textBox="textBox3"
      :photeSrc="require('../assets/img/light4.jpg')" />
    <HomeContentDetails />
    <HomeContentFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeContentHeader from '../components/HomeContentHeader.vue'
import HomeContentTotalText from '@/components/HomeContentTotalText.vue'
import HomeContentLeftPhote from '@/components/HomeContentLeftPhote.vue'
import HomeContentRightPhote from '@/components/HomeContentRightPhote.vue'
import HomeContent from '../components/HomeContent.vue'
import { useI18n } from 'vue-i18n'
import HomeContentDetails from '@/components/HomeContentDetails.vue'
import HomeContentFooter from '@/components/HomeContentFooter.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      titleOrg: 'text-custom-orange',
      textWhite: 'text-white',
      textDark: 'text-dark',
      photeSrc2: "require('../assets/img/light2.jpg')",
      photeSrc3: '../assets/img/light3.jpg',
      bgwhite: 'bg-white',
      bgWarning: 'bg-warning',
      text18: 'homepage.mainPart1Content2',
      textBox1: {
        title: 'homepageContent.mainPart1Title',
        firstText: 'homepageContent.mainPart1Content1',
        secondText: 'homepageContent.mainPart1Content2',
      },
      textBox2: {
        title: 'homepageContent.mainPart2Title',
        firstText: 'homepageContent.mainPart2Content1',
        secondText: 'homepageContent.mainPart2Content2',
      },
      textBox3: {
        title: 'homepageContent.mainPart3Title',
        firstText: 'homepageContent.mainPart3Content1',
        secondText: 'homepageContent.mainPart3Content2',
      },
      'homepageContent': {
        mainPart1Title: 'あらゆる空間に適したスタイリッシュで効率的な照明ソリューション',
        mainPart1Content1: '雰囲気、機能性、エネルギー効率を向上',
        mainPart1Content2: `SC-Light は、あらゆる住宅、商業、産業空間を強化するために設計された高品質の電気照明製品を幅広く提供しています。エレガントなシャンデリアから省エネ LED 電球まで、当社のソリューションはスタイルと効率性を備えてお客様のニーズを満たすようカスタマイズされています。`,
        mainPart2Title: '素晴らしい照明ソリューションで空間を照らします',
        mainPart2Content1: 'あらゆる部屋の雰囲気、機能性、エネルギー効率を向上させます。',
        mainPart2Content2: `SC-Light は、あらゆる空間の雰囲気、機能性、エネルギー効率を向上させるように設計された高品質の電気照明製品の大手プロバイダーです。豊富な品揃え、競争力のある価格、優れた顧客サービスを備えた当社は、照明のあらゆるニーズにお応えするワンストップ ショップです。`,
        mainPart3Title: 'Illuminate Your Space with High-Quality Lighting Solutions',
        mainPart3Content1: 'Enhance Ambiance, Functionality, and Energy Efficiency',
        mainPart3Content2: `LightingCo offers a wide range of high-quality electric lighting products designed to enhance the ambiance, functionality, and energy efficiency of any space. Whether you're a homeowner looking to create a cozy atmosphere or a business owner in need of stylish and efficient lighting solutions, we have you covered.`,
      },
    }
  },
  components: {
    HomeContent,
    HomeContentHeader,
    HomeContentTotalText,
    HomeContentLeftPhote,
    HomeContentRightPhote,
    HomeContentDetails,
    HomeContentFooter
  },
  // setup() {

  //   const { t } = useI18n();
  //   computed: {
  //     text3: t('HomePage')
  //   }
  // },
}
</script>

<style>
.bg-custom-blue {
  background-color: rgb(35, 13, 102);
}

.bg-custom-darkBlue {
  background-color: rgb(4, 9, 71);
}
</style>