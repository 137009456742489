<template>
    <div class="row bg-custom-red">
        <div class="col-2"></div>
        <div class="col-8 text-white text-center py-5 my-5">


            <h3> {{ $t('homepageFoot.title') }}</h3>
            <p class="py-2">{{ $t('homepageFoot.text') }}</p>
            <div class="py-2">
                <button class="btn btn-dark btn-lg  fs-6 mx-2 " @click="maintenence">{{ $t('homepageFoot.leftBtn')
                }}</button>
                <router-link to="./company">
                    <button class="btn btn-outline-warning btn-lg  fs-6 mx-2">{{ $t('homepageFoot.rightBtn')
                    }}</button></router-link>
            </div>
        </div>
        <div class="col-2"></div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            title: 'Discover the Perfect Lighting Solutions for Your Space',
            text: 'Enhance Ambiance, Functionality, and Energy Efficiency',
            leftBtn: 'SHOP NOW',
            rightBtn: 'CONTACT',
        }
    },
    methods: {
        maintenence() {
            alert('Coming soon');
        }
    }
}

</script>