<template>
    <div class="text-center col-lg-4 px-5 py-5">
        <div class="my-4 text-custom-orange">
            <i :class="cardIcon" class="fs-1"></i>
        </div>
        <h3 class="text-custom-orange fw-bold ">{{ cardTitle }}</h3>
        <p class="fs-6 py-2">{{ cardContent }}</p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cardIcon: this.cardBox.cardIcon,
            cardTitle: this.cardBox.cardTitle,
            cardContent: this.cardBox.cardContent,
        }
    },
    props: {
        cardBox: Object

    }
}
</script>