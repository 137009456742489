<template>
    <div class="bg-custom-red">
        <div class="row py-5">
            <!-- l-text -->
            <div class="col-lg-1"></div>
            <div class="col-lg-5 px-4 py-4 text-white col-sm-12">
                <div class="">
                    <h1 class="display-5 fw-bold">{{ $t('homepageHeader.title') }}</h1>
                    <!-- <hr class="custom-hr my-4"> -->
                    <p class="fs-5">{{ $t('homepageHeader.titleDetails') }}</p>
                </div>

                <router-link to="./company">
                    <button class="btn btn btn-dark btn-lg fs-6">
                        {{ $t('homepageHeader.btnText1') }}
                        <i class="bi bi-arrow-right"></i>
                    </button>
                </router-link>
                <br>
                <router-link to="./contact">
                    <button class="my-4 btn btn-outline-warning btn-lg  fs-6">
                        {{ $t('homepageHeader.btnText2') }}
                        <i class="bi bi-envelope"></i>
                    </button>
                </router-link>
            </div>
            <!-- r-phote -->
            <div class="col-lg-5 px-5 py-5 col-sm-12">
                <img src="../assets/img/light1.jpg" alt="" style="width: 100%; " class="rounded shadow">
            </div>
            <div class="col-lg-1">
            </div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>
<style scoped>
.custom-hr {
    margin-top: 7px;
    margin: 0;
    border: 0;
    color: white;
    background-color: white;
    height: 20px;
}

/* 初始样式 */
img {
    transition: transform 0.3s ease-in-out;
}

/* 鼠标悬停时应用的样式 */
img:hover {
    transform: rotate(5deg);
    /* 或者使用其他形式的变换 */
}
</style>