<template>
    <div class="py-5 row bg-dark">
        <div class="col col-lg-1"></div>
        <div class="col col-lg-10">
            <div class="row text-white">
                <div class="col-lg-3 parent-container">
                    {{ tradeMark }}
                </div>
                <div class="col-lg-3 text-custom-orange">
                    <router-link to="./company">
                        <p class="text-custom-orange">Company Info</p>
                    </router-link>
                    <router-link to="./contact">
                        <p class="text-custom-orange">Contact Me</p>
                    </router-link>

                </div>
                <div class="col-lg-3 ">
                    <router-link to="./product">
                        <p class="text-custom-orange">Product</p>
                    </router-link>
                    <router-link to="./applyArea">
                        <p class="text-custom-orange">Application</p>
                    </router-link>
                </div>
                <div class="col-lg-3">
                    <a href="https://tcllight.com/">
                        <p class="text-custom-orange">
                            InterNational Website
                        </p>
                    </a>
                    <router-link to="./aboutTCL">
                        <p class="text-custom-orange">About TCL</p>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="col col-lg-1"></div>
    </div>
</template>
<script>
import ProductView from '@/views/ProductView.vue';
import FooterCard from './FooterCard.vue';
export default {
    data() {
        return {
            tradeMark: 'Copyright ©2024 SC-Light. All rights reserved.',
            cardTitle1: 'Company',
            cardMsg1: ['About Me', 'Contact Us', 'Terms of Service'],
            cardTitle2: 'Services',
            cardMsg2: ['Development', 'Consulting'],
            cardTitle3: 'Connect',
            cardMsg3: ['FaceBook', 'Linkedin', 'Twitter', 'Instgram'],
            shouldAddRouterLink1: true,
            shouldAddRouterLink2: false,
            shouldAddRouterLink3: false,
            yourRouterLinkPath1: ['./company', './contact'],

        }
    },
    components: {
        FooterCard,
    },
}


</script>
<style scoped>
.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.router-link-active {
    text-decoration: none;
    color: black;
}

.routet-link {
    text-decoration: none;
    color: black;
}

a {
    text-decoration: none;
    color: black;
}
</style>