import { createI18n } from 'vue-i18n'
import zhLang from './zh'
import jaLang from './ja'
import enLang from './en'

const messages = {
    en: 
        enLang
    ,
    ja: 
        jaLang
    ,
    zh:
        zhLang
}

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: 'ja', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

export default i18n