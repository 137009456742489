<template>
    <div :class="bg">
        <div class="row py-5">
            <div class="col-lg-1"></div>
            <div class="col-sm-12 col-lg-5 px-4 py-4 text-white ">
                <!-- l-text -->
                <div class="col-lg-10 px-4 py-4">
                    <h1 class="fw-bold" :class="title">{{ $t(textBox.title) }}</h1>
                    <div :class="text">
                        <p class="fs-5">{{ $t(textBox.firstText) }}</p>
                        <p class="fs-5">{{ $t(textBox.secondText) }}</p>
                    </div>
                </div>
            </div>

            <!-- r-phote -->
            <div class="col-sm-12 col-lg-5 px-5 py-5 parent-container">
                <img :src="photeSrc" alt="" style="width: 100%; " class="rounded shadow">
            </div>

            <div class="col-lg-1">
            </div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            getPhoteSrc: this.photeSrc,
            bgClass: 'bg-warning',
            mainPart1Title: 'Lighting Solutions that Illuminate Your Space',
            mainPart1Content1: 'Elevate Your Ambiance, Functionality, and Energy Efficiency',
            mainPart1Content2: `SC-Light offers a wide range of high-quality electric lighting products designed to enhance any residential, commercial, or industrial space. From elegant chandeliers to energy-saving LED bulbs, our solutions are tailored to meet your needs with style and efficiency.`,

        }
    },
    props: {
        title: String,
        text: String,
        photeSrc: String,
        bg: String,
        textBox: Object,
    },

    mounted() {
        console.log(this.photeSrc)
        console.log(this.textBox)
    }
}
</script>

<style scoped>
.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>