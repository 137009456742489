export default {
    'navbar': {
        navBarBrand: 'SC-Light',
        navBarItem1: 'ホームページ',
        navBarItem2: '製品',
        navBarItem3: '会社情報',
        navBarItem4: '問い合わせ',
        navBarItem5: '翻訳',
        navBarItem6: '応用範囲',
        navBarItem7: '製品情報',
    },
    'homepage': {
        btnMsg: '詳しく見る',
        mainPart1Title: '空間を照らす照明ソリューション',
        mainPart1Content1: '雰囲気、機能性、エネルギー効率を向上',
        mainPart1Content2: `SC-Light は、あらゆる住宅、商業、産業空間を強化するために設計された高品質の電気照明製品を幅広く提供しています。エレガントなシャンデリアから省エネ LED 電球まで、当社のソリューションはスタイルと効率性を備えてお客様のニーズを満たすようカスタマイズされています。`,

    },
    'homepageHeader': {
        title: '素晴らしい照明ソリューションで空間を照らします',
        titleDetails: '雰囲気、機能性、エネルギー効率を向上どの部屋でも',
        mainPart1Title: '空間を照らす照明ソリューション',
        mainPart1Content1: '雰囲気、機能性、エネルギー効率を向上',
        mainPart1Content2: `SC-Light は、高品質の電気照明製品を提供する大手プロバイダーです。居心地の良い雰囲気を作りたい住宅所有者でも、スタイリッシュで効率的な照明ソリューションを必要としているビジネスオーナーでも、当社が対応します。当社の幅広い照明オプションは、住宅、商業、産業用途に対応します。エレガントなシャンデリアから省エネ LED 電球まで、当社の製品はあらゆる空間を向上させるようにデザインされています。競争力のある価格と卓越した顧客サービスを備えた SC-Light は、あらゆる照明ニーズに応えるワンストップ ショップです。`,
        btnText1: `当社の情報をご覧ください`,
        btnText2: '連絡してみよう'
    },
    'homepageContent': {
        mainPart1Title: 'あらゆる空間に適したスタイリッシュで効率的な照明ソリューション',
        mainPart1Content1: '雰囲気、機能性、エネルギー効率を向上',
        mainPart1Content2: `SC-Light は、あらゆる住宅、商業、産業空間を強化するために設計された高品質の電気照明製品を幅広く提供しています。エレガントなシャンデリアから省エネ LED 電球まで、当社のソリューションはスタイルと効率性を備えてお客様のニーズを満たすようカスタマイズされています。`,
        mainPart2Title: '素晴らしい照明ソリューションで空間を照らします',
        mainPart2Content1: 'あらゆる部屋の雰囲気、機能性、エネルギー効率を向上させます。',
        mainPart2Content2: `SC-Light は、あらゆる空間の雰囲気、機能性、エネルギー効率を向上させるように設計された高品質の電気照明製品の大手プロバイダーです。豊富な品揃え、競争力のある価格、優れた顧客サービスを備えた当社は、照明のあらゆるニーズにお応えするワンストップ ショップです。`,
        mainPart3Title: '高品質の照明ソリューションで空間を照らします',
        mainPart3Content1: '雰囲気、機能性、エネルギー効率を向上',
        mainPart3Content2: `SC-Light は、あらゆる空間の雰囲気、機能性、エネルギー効率を高めるために設計された高品質の電気照明製品を幅広く提供しています。居心地の良い雰囲気を作りたい住宅所有者でも、スタイリッシュで効率的な照明ソリューションを必要としているビジネスオーナーでも、当社が対応します。`,
        cardArray: [
            {
                cardIcon: 'bi bi-search',
                cardTitle: 'Wide Range of Lighting Solutions',
                cardContent: 'Find the Perfect Fit for Your Space',
            },
            {
                cardIcon: 'bi-lightbulb-fill',
                cardTitle: 'Enhance Ambiance and Functionality',
                cardContent: 'Create the Perfect Atmosphere for Any Occasion',
            },
            {
                cardIcon: 'bi bi-graph-up-arrow',
                cardTitle: 'Energy-Efficient Options Available',
                cardContent: 'Subheadline: Save on Energy Bills while Reducing Environmental Impact',
            },
            {
                cardIcon: 'bi bi-lightning-charge-fill',
                cardTitle: 'Adjustable Brightness Options',
                cardContent: 'Explore Lighting Products with Customizable Brightness Levels.',
            },
            {
                cardIcon: 'bi bi-person-plus',
                cardTitle: 'Designed for All Users',
                cardContent: 'Lighting Solutions Tailored for Homeowners and Professionals.',
            },
            {
                cardIcon: 'bi bi-bullseye',
                cardTitle: 'Excellence in Every Aspect',
                cardContent: 'Our Lighting Aims for Efficiency, Aesthetics, and Versatility.',
            },
        ],
    },
    'homepageFoot':{
        title: 'あなたの空間に最適な照明ソリューションを見つけてください',
        text: '雰囲気、機能性、エネルギー効率を向上',
        leftBtn: '購入',
        rightBtn: '連絡',
    },
    'product': {
        title: 'プロの顧客向けの製品ポータル',
        subtitle: 'ここで製品ポートフォリオ全体を一目で確認できます',
        titleText: `当社は、一般照明において世界で最も包括的な製品ポートフォリオの 1 つを提供しています。あなたのプロジェクトのための最新の照明ソリューション。エネルギー効率が高く、耐久性があり、柔軟に使用できます。ほぼあらゆる目的に。さまざまな LED 照明器具、電子照明コンポーネント、スマート照明ソリューション、LED ランプの中からお選びいただけます。`,
        mainTitle: ' 製品カタログ',
        mainSubTitle: 'すべての技術データとデータシート',
        productName1: '屋内照明',
        productName2: '屋外照明',
        productName3: '商業用照明',
        productName4: '産業用照明',
    },
    'aboutTCL': {
        mainText1: `TCL は 1981 年に設立され、以前は中国最初の合弁会社の 1 つである TTK ホーム アプライアンス Co., Ltd. として知られ、当初はテープの生産と製造に従事していましたが、後に電話、テレビ、携帯電話などに事業を拡大しました。電話、エアコン、冷蔵庫、洗濯機、照明、小型家電などの産業から、インテリジェント端末、半導体ディスプレイ、新エネルギー太陽光発電、産業金融・投資プラットフォームなどのレイアウトまで、事業範囲は多岐にわたります。拡大する。 TCLはこれまでに世界中に46の研究開発センターと32の製造拠点を展開し、世界160以上の国と地域で事業を展開し、世界的に競争力のあるスマートテクノロジー業界グループとなっている。`,
        mainText2: `TCL Huarui Lighting は、TCL Lighting と TCL Group の子会社である Huarui Light Source が合併して 2000 年に設立され、LED および LED アプリケーション ソリューションの研究開発、生産、販売を統合するハイテク企業です。同社は、先進的なマイクロLEDディスプレイ、ミニLEDバックライトおよびディスプレイおよびその他のコンポーネント事業、ならびにLEDグリーン照明およびスマート照明事業の開発に注力しており、「中国グリーン照明教育デモンストレーションベース」の名誉称号を獲得しています。"`,
        mainText3: `現在、TCL Huarui Lightingは複数の独立したミニLED開発および生産ラインと完全なプロセス制御システムを備えており、業界チェーンの包括的な有利なリソースと協力して、最高の製品アプリケーションソリューションを提供しています。 TCL Huarui Lighting は、高度な技術ソリューションを頼りに、テレビ分野の顧客と協力して、複数のカスタマイズされたミニ LED バックライト モジュール プロジェクトを完了し、満場一致で評価されました。.
        `,
        mainText4: `LED 照明アプリケーションの分野では、TCL Huarui Lighting は 20 年以上にわたり業界に深く関わっており、革新的な技術の研究開発に依存し、健康とインテリジェント照明を開発の焦点としています。都市交通、オフィス、住宅、不動産などの専門的なサービスを提供しています。商業、教育、医療、産業、アウトドアなどのさまざまな分野で、全国の大都市および中規模都市にオフィスを構え、全省をカバーするチャネルを持ち、当社は、お客様に高品質な照明製品、総合的なソリューション、サービスを提供するとともに、海外市場への積極的な展開を図っており、ヨーロッパ、米国、中東、東南アジア。`,
        mainText5: `TCL Huarui Lightingは、業界に先駆けてCNASに認められた国家レベルの照明研究所を設立し、EMC、ゴニオフォトメトリなどの業界のベンチマーク専門研究所を設立し、米国のUL WTDP立会人試験研究所の資格認証に合格し、国際的な照明研究所を設立しました。専門的な品質管理システムを備えており、産学界および研究分野の多くの有名大学と協力して強力な研究開発力を形成し、科学技術革新の競争力を常に維持しています。`,
        mainText6: `TCL Huarui Lightingは、LED光源デバイスのパッケージング分野における品質と技術の蓄積、およびLED分野における「パッケージング+バックライトモジュール+照明アプリケーション」の業界チェーンプラットフォーム全体に基づいて、業界で尊敬されるリーダーになるよう努めています。先進的なディスプレイと健康的なインテリジェント照明。`,
    },
}