<template>
    <h5>{{ title }}</h5>
    <div class="py-2">
        <router-link v-if="shouldAddRouterLink" :to="getRouterLinkPath">
            <p class="text-custom-orange" v-for="(item, index) in msgBox" :key="index">
                {{ item }}
            </p>
        </router-link>
        <template v-else>
            <p v-for="(item, index) in msgBox" :key="index" class="text-custom-orange">
                {{ item }}
            </p>
        </template>
    </div>
</template>
  
<script>
import router from '@/router';

export default {
    data() {
        return {
            // yourRouterLinkPath1: ['./company', './contact']
        }
    },
    props: {
        title: String,
        msgBox: Array,
        shouldAddRouterLink: Boolean,
        yourRouterLinkPath: Array
    },
    computed: {
        getRouterLinkPath() {
            // 如果 yourRouterLinkPath1 是数组，返回包含多个 router-link 的数组
            if (Array.isArray(this.yourRouterLinkPath)) {
                return this.yourRouterLinkPath.map(path => ({ path }));
            } else {
                return this.yourRouterLinkPath;
            }
        }
    },
    components: { router }
}
</script>
  
<style scoped>
.router-link-active {
    text-decoration: none;
    color: black;
}

.routet-link {
    text-decoration: none;
    color: black;
}

a {
    text-decoration: none;
    color: black;
}
</style>
  