<template>
    <nav class="navbar navbar-expand-lg" :class="{ cssBgMode: ture, cssNavMode: ture, cssTextMode: ture }">
        <div class="container-fluid px-4">
            <a class="navbar-brand" href="#">SC-Light</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/"><a class="nav-link fw-bold" aria-current="page" href="#">{{
                            $t('navbar.navBarItem1')
                        }}</a></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/company">
                            <a class="nav-link" href="#">{{ $t('navbar.navBarItem3')
                            }}</a>
                        </router-link>
                    </li>
                    <li class=""></li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> -->
                    <li class="nav-item">
                        <router-link to="/product">
                            <a class="nav-link" href="#">{{ $t('navbar.navBarItem7') }}</a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/applyArea">
                            <a class="nav-link" href="#">{{ $t('navbar.navBarItem6') }}</a>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/contact">
                            <a class="nav-link" href="#">{{ $t('navbar.navBarItem4') }}</a>
                        </router-link>
                    </li>
                </ul>
                <div class="d-flex list-unstyled">
                    <div class="px-2">
                        <a href="" @click.prevent="changeMode">
                            <i class="bi bi-moon-stars"></i>
                            Mode
                        </a>
                    </div>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-globe"></i>
                            Transl.
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li class="mt-2"><a class="dropdown-item" href="#" @click="changeLang('ja')">{{ navBarMenu1
                            }}</a>
                            </li>
                            <li class="my-2"><a class="dropdown-item" href="#" @click="changeLang('en')">{{ navBarMenu2
                            }}</a>
                            </li>
                            <li class="my-2"><a class="dropdown-item" href="#" @click="changeLang('zh')">{{ navBarMenu3
                            }}</a>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import i18n from '@/lang/index'
export default {

    data() {
        return {
            navBarMenu1: '日本語',
            navBarMenu2: 'English',
            navBarMenu3: '简体中文',
            cssBgMode: 'bg-light',
            cssTextMode: 'text-dark',
            cssNavMode: 'nav-light',
        }
    },
    methods: {
        changeLang(lang) {
            i18n.global.locale = lang;
        },
        changeMode() {
            console.log('test');
            if (this.cssBgMode === 'bg-light') {
                this.cssBgMode = 'bg-dark';
                this.cssTextMode = 'text-white';
                this.cssNavMode = 'nav-dark';
            } else if (this.cssBgMode === 'bg-dark') {
                console.log('test2');
                this.cssBgMode = 'bg-light';
                this.cssTextMode = 'text-dark';
                this.cssNavMode = 'nav-light';
            }
        }
    }
}
</script>
<style scoped>
.router-link-active {
    text-decoration: none;
    color: black;
}

.routet-link {
    text-decoration: none;
    color: black;
}

a {
    text-decoration: none;
    color: black;
}
</style>